<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>在线制版平台</el-breadcrumb-item>
                <el-breadcrumb-item>内部管理</el-breadcrumb-item>
                <el-breadcrumb-item><a href="">人员权限</a></el-breadcrumb-item>
                <el-breadcrumb-item><a href="">角色列表</a></el-breadcrumb-item>
            </el-breadcrumb>

            <FixedSearchForm v-model="searchForm" :items="searchItems" @search="handleSearch">
                <el-button type="primary" @click="openEditor">新增</el-button>
            </FixedSearchForm>
            <el-table :data="tableData" stripe border size="small"
                     header-cell-class-name="table_header"
                    cell-class-name="table_cell" style="width: 100%">
                <el-table-column
                        prop="name"
                        label="角色名"
                        min-width="100"
                >
                </el-table-column>
                <el-table-column
                        prop="createdate"
                        label="创建日期">
                </el-table-column>
                <el-table-column
                        label="状态">
                    <template slot-scope="scope">
                        <el-tag
                                :type="g_getRoleStatus(scope.row.status,true)"
                                disable-transitions>{{g_getRoleStatus(scope.row.status)}}</el-tag>
                    </template>
                </el-table-column>

                <el-table-column
                        min-width="180"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="openUsers(scope.row.id)">
                            用户列表 <i class="el-icon-user-solid"></i>
                        </el-button>
                        <el-divider direction="vertical"></el-divider>
                        <template v-if="!scope.row.root">
                            <el-popconfirm
                                    confirm-button-text='好的'
                                    cancel-button-text='不用了'
                                    icon="el-icon-delete"
                                    icon-color="red"
                                    :title="`删除角色[${scope.row.name}](用户列表必须为空)?`"
                                    @confirm="delHandle(scope.row.id)"
                            >
                                <el-button slot="reference" type="text">
                                    删除 <i class="el-icon-delete"></i>
                                </el-button>
                            </el-popconfirm>
                            <el-divider direction="vertical"></el-divider>
                        </template>
                        <el-button type="text" @click="openMenus(scope.row.id)">
                            分配菜单 <i class="el-icon-menu"></i>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-sizes="[10, 20, 50, 100]"
                    :current-page="page"
                    :page-size="limit"
                    :total="total">
            </el-pagination>
            <el-dialog
                    title="用户列表"
                    :visible.sync="userListVisible"
                    width="30%">
                <el-form :model="editForm" ref="usersForm" label-width="80px" :inline="false">
                    <el-form-item label="角色名" prop="name">
                        <el-input v-model="editForm.name"  ></el-input>
                    </el-form-item>
                    <el-form-item label="是否启用" prop="status">
                        <el-radio-group v-model="editForm.status">
                            <el-radio :label="1">停用</el-radio>
                            <el-radio :label="0">启用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="用户列表">
                        <el-tag style="margin-left: 10px;" :key="index" v-for="(user,index) in editForm.users">{{user.name}}</el-tag>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitHandle('usersForm')">确 定</el-button>
                </span>
            </el-dialog>
            <el-dialog title="添加角色" :visible.sync="editFormVisible" width="600px" center>
                <el-form :model="editForm" :rules="rules" ref="editForm" label-width="80px">
                    <el-form-item label="角色名" prop="name">
                        <el-input v-model="editForm.name"  ></el-input>
                    </el-form-item>
                    <el-form-item label="是否启用" prop="status">
                        <el-radio-group v-model="editForm.status">
                            <el-radio :label="1">停用</el-radio>
                            <el-radio :label="0">启用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item size="large">
                        <el-button type="primary" @click="submitHandle('editForm')">确认</el-button>
                        <el-button @click="editFormVisible=false">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <el-dialog
                    title="分配菜单"
                    :visible.sync="menuListVisible"
                    width="30%">
<!--                :default-expanded-keys="[2, 3]"-->
<!--                :default-checked-keys="[5]"-->
                <el-tree
                        :data="allMenus"
                        :show-checkbox="!advanced"
                        :draggable="advanced"
                        default-expand-all
                        ref="menuTree"
                        node-key="id"
                        :props="{label:'name'}"
                        :expand-on-click-node="!advanced">

                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <el-tag size="small" :type="g_getMenuTagColor(data)">{{ data.name }} {{suffix(data)}}</el-tag>
                        <span style="margin-left: 30px" v-if="!data.routeComponent && advanced">
                          <el-button
                                  type="text"
                                  size="mini"
                                  @click="() => appendMenu(data)">
                            <i class="el-icon-folder-add"></i>
                          </el-button>
                            <el-divider direction="vertical"></el-divider>
                                <el-button v-show="data.type === 'custom'"
                                           type="text"
                                           size="mini"
                                           @click="() => editMenu(data)">
                            <i class="el-icon-edit"></i>
                          </el-button>
                            <el-divider direction="vertical"></el-divider>
                                   <template>
                                        <el-popconfirm
                                                v-show="data.type === 'custom'"
                                                confirm-button-text='好的'
                                                cancel-button-text='不用了'
                                                icon="el-icon-delete"
                                                icon-color="red"
                                                :title="`删除标签[${data.name}](不删除子菜单)？`"
                                                @confirm="removeMenu(node, data)"
                                        >
                                             <el-button slot="reference"
                                                        type="text"
                                                        size="mini">
                                            <i class="el-icon-delete"></i>
                                          </el-button>
                                        </el-popconfirm>
                                    </template>
                        </span>
                      </span>

                </el-tree>

                <span slot="footer" class="dialog-footer">
<!--                <el-switch-->
<!--                        style="margin-right: 50px;"-->
<!--                 v-model="advanced"-->
<!--                        @change="setMode"-->
<!--                 active-text="高级模式"-->
<!--                 inactive-text="普通模式">-->
<!--                </el-switch>-->
                <el-button @click="menuListVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitMenuOrdersHandle">保 存</el-button>
              </span>
                <el-dialog :title="editMenuForm.id?'编辑菜单':'新增菜单'" :visible.sync="editMenuFormVisible" append-to-body>
                    <el-form :model="editMenuForm" :rules="menuRules" ref="editMenuForm">
                        <el-form-item label="名称" prop="name" :label-width="80">
                            <el-input v-model="editMenuForm.name" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="editMenuFormVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submitMenuHandle('editMenuForm')">确 定</el-button>
                    </div>
                </el-dialog>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
    import {menus2orders, orders2menus, orders2array, UNLINK_INDEX, checkorders} from "../../global";
    import FixedSearchForm from "../common/FixedSearchForm";
    import {Search_Type} from "../../constants";
    const prefix_role = '/sys/role';
    const roleGetListUrl = `${prefix_role}/list`;
    const roleGetOneUrl = function (id) {
        return `${prefix_role}/one/${id}`;
    }
    const rolePutUrl = function (id) {
        return `${prefix_role}/update/${id}`;
    }
    const rolePostUrl = `${prefix_role}/save`;
    const roleDeleteUrl = function (id) {
        return `${prefix_role}/delete/${id}`;
    }

    const prefix_menu = '/sys/menu';
    const menuGetListUrl = function (id) {
        return `${prefix_menu}/role/${id}`;
    }
    const menuPutUrl = function (id) {
        return `${prefix_menu}/role/update/${id}`;
    }
    const menuPostUrl = `${prefix_menu}/role/save`;
    const menuDeleteUrl = function (id) {
        return `${prefix_menu}/role/delete/${id}`;
    }
    const menuSaveOrderUrl = function (id) {
        return `${prefix_menu}/role/updateOrder/${id}`;
    }

    export default {
        components:{FixedSearchForm},
        data(){
            // "id": 1,
            // "name": "employ",
            // "createdate": "2010-2-2 12:11:10",
            // "status": 0,
            // "menulist":[]
            return {
                tableData:[],
                page: 1,
                limit: 10,
                total: 0,
                editFormVisible: false,         //新增
                userListVisible: false,         //用户列表
                menuListVisible: false,         //菜单分配
                editMenuFormVisible: false,     //菜单编辑
                editForm: {},
                searchForm: {},
                searchItems:[
                    {
                        id: 1,
                        label: '角色名',
                        name: 'name',
                        type: Search_Type.Input,
                    },
                    {
                        id: 3,
                        label: '状态',
                        name: 'status',
                        type: Search_Type.Select,
                        props:{
                            0: '启用',
                            1: '停用',
                        }
                    },
                ],
                editMenuForm: {},
                nodedata: {},
                allMenus: [],       //与tree界面绑定的数据
                menuList: [],       //后台获取的menulist
                customOrders: [],   //后台获取的自定义orders
                sysOrders: [],      //系统默认orders
                advanced: false,
                rules:{
                    name: [
                        { required: true, message: '请输入角色名', trigger: 'blur'},
                        { min: 3, max: 32, message: '长度在 3 到 32 个字符', trigger: 'blur' },
                        { pattern: /^\S.*\S$|(^\S{0,1}\S$)/,  message: '首尾不能为空格', trigger: 'blur' }
                    ]
                },
                menuRules:{
                    name: [
                        { required: true, message: '请输入菜单名', trigger: 'blur'},
                        { min: 3, max: 32, message: '长度在 3 到 32 个字符', trigger: 'blur' },
                        { pattern: /^\S.*\S$|(^\S{0,1}\S$)/,  message: '首尾不能为空格', trigger: 'blur' }
                    ]
                },

            }
        },
        created() {
            this.getTableData();
        },
        methods: {
            getTableData(){
                this.$axios.get(roleGetListUrl, {
                    params: {
                        ...this.g_getPage(this.page,this.limit),
                        ...this.searchForm
                    }
                }).then(res=>{
                    this.tableData = res.data.records;
                    this.page = res.data.current;
                    this.limit = res.data.size;
                    this.total = res.data.total;
                })
            },
            handleSizeChange(limit){
                this.limit = limit;
                this.getTableData();
            },
            handleCurrentChange(page){
                this.page = page;
                this.getTableData();
            },
            handleSearch(){
              this.page = 1;
              this.getTableData();
            },
            //人员列表
            openUsers(id){
                this.editForm = {};
                this.$axios.get(roleGetOneUrl(id)).then(res=>{
                    this.editForm = res.data.role;
                    this.editForm.users = res.data.users;
                    this.userListVisible = true;
                })
            },
            //新增角色
            openEditor(){
                this.editForm = {};
                this.editFormVisible = true;
            },
            //分配菜单
            openMenus(id){
                this.editForm = {id: id};
                this.allMenus = [];
                this.menuList = [];
                this.orders = [];
                this.advanced = false;
                this.menuListVisible = true;
                this.$axios.get(menuGetListUrl(id)).then(res => {
                    this.menuList = res.data.menuList;
                    this.customOrders = JSON.parse(res.data.customOrders);
                    this.sysOrders = JSON.parse(res.data.systemOrders);
                    if(this.advanced){
                        this.setAdvancedMenu();
                    }else{
                        this.setBasicMenu();
                    }
                })
            },
            setMode(){
                if(this.advanced){
                    this.setAdvancedMenu();
                }else{
                    this.setBasicMenu();
                }
            },
            setBasicMenu(){
                this.allMenus = [];
                //构造树
                let menumap = new Map();
                this.menuList.forEach(menu => {
                    menumap.set(menu.id, {...this.g_deepClone(menu), children:[]});
                })
                this.allMenus = orders2menus(this.sysOrders, menumap, {ignoreDelete: true, ignoreDisable: true});
                this.allMenus = this.allMenus.filter(menu=>menu.children.length>0);
                //构造勾选框
                let keys = orders2array(this.customOrders, {menuMap:menumap, onlyLeaf:true});
                console.log(keys);
                this.$refs.menuTree.setCheckedKeys(keys);
            },
            setAdvancedMenu(){
                this.allMenus = [];
                //构造树
                let menumap = new Map();
                let sysIds = orders2array(this.sysOrders);
                this.menuList.forEach(menu => {
                    let newMenu = {...this.g_deepClone(menu), children:[]};
                    if(sysIds.indexOf(menu.id) >= 0){
                        newMenu.type = 'system';
                    }else{
                        newMenu.type = 'custom';
                    }
                    menumap.set(menu.id, newMenu);
                })
                this.allMenus = orders2menus(this.customOrders, menumap, {removeMenu: true});
                //构造未使用的菜单项
                if(menumap.size > 0){
                    let unlink_tags = {
                        id: UNLINK_INDEX,
                        name: '未关联标签',
                        children: []
                    }
                    let unlink_pages = {
                        id: UNLINK_INDEX*2,
                        name: '未关联页面',
                        children: []
                    }
                    menumap.forEach(menu => {
                        if(menu.routeComponent){
                            unlink_pages.children.push(menu);
                        }else{
                            unlink_tags.children.push(menu);
                        }
                    })
                    this.allMenus.push(unlink_pages);
                    this.allMenus.push(unlink_tags);
                }
            },
            suffix(data){
                if(this.advanced) {
                    if(data.id >= UNLINK_INDEX) return '';
                    if(data.routeComponent) return '[页面]';
                    else{
                        return (data.type && data.type==="custom")?'[用户标签]':'[系统标签]';
                    }
                }
                else{
                    return '';
                }
            },
            //删除角色
            delHandle(id){
                this.$axios.delete(roleDeleteUrl(id)).then(res=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            //新增修改角色
            submitHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.editForm.id){
                            this.$axios.put(rolePutUrl(this.editForm.id), this.editForm).then(res=>{
                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose:() => {
                                        this.getTableData()
                                    }
                                });
                                this.userListVisible = false
                            })
                        }else{
                            this.$axios.post(rolePostUrl, this.editForm).then(res=>{
                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose:() => {
                                        this.getTableData()
                                    }
                                });
                                this.editFormVisible = false
                            });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //保存菜单顺序
            submitMenuOrdersHandle(){
                let orders = [];
                if(!this.advanced){
                    let checkedNodes = this.$refs.menuTree.getCheckedNodes(false, true);
                    let checkedKeys = checkedNodes.map(node=>node.id);
                    orders = menus2orders(this.allMenus, checkedKeys);
                }else{
                    let result = checkorders(this.allMenus);
                    if(result.length > 0){
                        this.$message.error(`不符合(1)页面不能成为父节点(2)叶节点必须是页面,${result}[标签]位置错误`);
                        return ;
                    }
                    orders = menus2orders(this.allMenus);
                }
                this.$axios.put(menuSaveOrderUrl(this.editForm.id), {
                    menuTree: JSON.stringify(orders)
                }).then(res=>{
                    this.$message.success("保存成功");
                    this.menuListVisible = false;
                });
            },
            //高级模式菜单相关操作
            //创建标签
            appendMenu(data) {
                this.editMenuForm = {};
                this.nodedata = data;
                this.editMenuFormVisible = true;
            },
            //编辑标签
            editMenu(data){
                this.editMenuForm = data;
                this.editMenuFormVisible = true;
            },
            //删除标签
            removeMenu(node, data) {
                const parent = node.parent;
                const children = parent.data.children || parent.data;
                const index = children.findIndex(d => d.id === data.id);
                this.$axios.delete(menuDeleteUrl(data.id)).then(res=>{
                    this.$message.success("删除成功");
                    children.splice(index, 1);
                })
            },
            //新建修改标签数据保存
            submitMenuHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.editMenuForm.id){
                            if(!this.editMenuForm.children) this.editMenuForm.children = [];
                            this.$axios.put(menuPutUrl(this.editMenuForm.id), this.editMenuForm).then(res=>{
                                this.$message.success("修改成功");
                            })
                        }else{
                            const newChild = { ...this.editMenuForm, children: [], type: 'custom', status: 0};
                            this.$axios.post(menuPostUrl, newChild).then(res=>{
                                this.$message.success("新增成功");
                                newChild.id = res.data.id
                                if (!this.nodedata.children) {
                                    this.$set(this.nodedata, 'children', []);
                                }
                                this.nodedata.children.push(newChild);
                                this.nodedata = {}
                            })

                        }
                        this.editMenuFormVisible = false;
                    } else {
                        return false;
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>